import AuthBase from "../AuthBase";
import {Avatar, Stack, useTheme} from "@mui/material";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import useCallDataApi from "../../hooks/data";
import {useContext, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConfigContext from "../../context/ConfigContext";
import DinoDialog from "../../components/Dialog";
import Button from "@mui/material/Button";
import {getFormDataNative} from "../../utils/FormUtils";
import CircleButton from "../../components/CircleButton";
import {useHistory} from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Loading from "../../components/Loading";
import ErrorContext from "../../context/ErrorContext";
import ExtraItemData from "./extraItemData";


const ExtraItem = ({id, name, description, blur, image, numberOfServices, edit, destroy}) => {
    const theme = useTheme()
    const history = useHistory()
    const {baseUrl} = useContext(ConfigContext)

    return <Stack sx={{flexDirection: {xs: 'column', sm: 'column', md: 'row'}}} spacing={2} style={{width: '100%', justifyContent: 'space-between'}}>
        <Stack direction='row' spacing={2}>

            <Avatar src={image?.startsWith('http') ? image : baseUrl + image} sx={{
                width: 60,
                height: 60,
                border: '2px solid',
                borderColor: theme.palette.primary[200],
                alignSelf: 'center',
            }}/>
            <div style={{alignSelf: 'center'}}>
                <Typography variant="subtitle1" style={{color: theme.palette.primary[600]}}>{name}</Typography>
                <Typography variant="body2" style={{color: '#536B74'}}>{description.substring(0, 30)}{description?.length > 30 && '...'}</Typography>
            </div>
        </Stack>

        <Stack spacing={1} direction='row' sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}>
            <CircleButton icon={<DeleteRoundedIcon/>} onClick={destroy}/>
            <CircleButton icon={<EditIcon/>} onClick={edit}/>
            <CircleButton icon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/extra-items/${id}`)
            }} inverted/>
        </Stack>

        <Stack spacing={1} sx={{display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
            <Button variant='outlined' endIcon={<EditIcon/>} onClick={edit}>Szerkesztés</Button>
            <Button variant='contained' endIcon={<KeyboardArrowRightIcon/>} onClick={() => {
                history.push(`/extra-items/${id}`)
            }}>Megnyitás</Button>
        </Stack>
    </Stack>
}

const ExtraItems = () => {
    const [extraItems, setExtraItems] = useState([])
    const [current, setCurrent] = useState(1)
    const [editOpen, setEditOpen] = useState(false)
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const {setPictureError} = useContext(ErrorContext)


    const {
        getData: fetchExtraItems,
        formDataUpdateData: updateExtraItem,
        deleteData: deleteExtraItem,
        formDataCreateData: createExtraItem
    } = useCallDataApi('extra-items')
    const serviceForm = useRef(null)

    const buttons = [{
        name: 'Új tétel hozzáadása',
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlinedIcon/>,
            onClick: () => {
                setNewOpen(true)
            }
        }
    }]

    const filterButtons = [
        {
            name: 'Új tétel',
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlinedIcon/>,
                onClick: () => {
                    setNewOpen(true)
                }
            }
        }
    ]

    const getExtraItem = (id) => {
        return extraItems.find(c => c.id === id)
    }

    const newExtraItem = () => {
        setLoading(true)
        const formDataNative = getFormDataNative(serviceForm.current);
        if (formDataNative) {
            const noPicture = !formDataNative.has('image')
            if (noPicture) {
                setPictureError(true)
                setLoading(false)
                return false
            }
            createExtraItem(formDataNative).then(c => {
                if (c) setExtraItems([...extraItems, c])
            }).finally(() => setLoading(false))
            return true
        }
        setLoading(false)
        return false
    }

    const editExtraItem = () => {
        setLoading(true)
        const formDataNative = getFormDataNative(serviceForm.current);
        if (formDataNative) {
            updateExtraItem(`${current}`, formDataNative).then(c => {
                if (c) {
                    const updated = extraItems.map(r => {
                        if (r.id !== c.id) return r
                        else return c
                    })
                    setExtraItems(updated)
                }
            }).finally(() => setLoading(false))
            return true
        }
        else {
            setLoading(false)
            return false
        }

    }

    const removeExtraItem = (id) => {
        setLoading(true)
        deleteExtraItem(id).then(() => {
            setExtraItems([...extraItems.filter(c => c.id !== id)])
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        const getData = async () => {
            const extra = await fetchExtraItems('get_for_business')
            if (extra) setExtraItems(extra)
        }
        getData()
            .then(() => setLoading(false))
    }, [])

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            removeExtraItem(current)
        }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
    </>
    const editActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setEditOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            if (editExtraItem()) setEditOpen(false)
        }} endIcon={<EditIcon/>}>Szerkesztés</Button>
    </>
    const newActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            if (newExtraItem()) setNewOpen(false)
        }} endIcon={<AddCircleOutlinedIcon/>}>Hozzáadás</Button>
    </>


    return <AuthBase label='Extra Tételek' buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <Typography variant='subtitle2' style={{color: '#536B74'}} gutterBottom>{extraItems?.length} tétel</Typography>
            <div className="white-card">
                <Stack spacing={2}>
                    {extraItems.map((c, i) =>
                        <ExtraItem key={`ExtraItem_${i}`}
                                  name={c.name}
                                  id={c.id}
                                  description={c.description}
                                  blur={c.blur}
                                  image={c.image}
                                  numberOfServices={c.number_of_services}
                                  edit={() => {
                                      setCurrent(c.id)
                                      setEditOpen(true)
                                  }}
                                  destroy={() => {
                                      setCurrent(c.id)
                                      setDeleteOpen(true)
                                  }}/>
                    )}
                </Stack>
            </div>
        </div>

        <DinoDialog open={newOpen}
                    title='Új tétel hozzáadása'
                    subtitle='Hozz létre egyedi kategóriákat és ahhoz tartozó szolgáltatásokat egyszerűen. Add meg a tétel nevét és leírását!'
                    actions={newActions}
                    handleClose={() => setNewOpen(false)}>
            <ExtraItemData reference={serviceForm}/>
        </DinoDialog>

        <DinoDialog open={editOpen}
                    title={`${getExtraItem(current)?.name} tétel szerkesztése`}
                    subtitle='Add meg a szolgáltatás nevét és leírását!'
                    actions={editActions}
                    handleClose={() => setEditOpen(false)}>
            <ExtraItemData obj={getExtraItem(current)} reference={serviceForm}/>
        </DinoDialog>

        <DinoDialog open={deleteOpen}
                    title={`Biztosan kitörli ezt: ${getExtraItem(current)?.name}`}
                    subtitle='Az összes hozzá tartozó szolgáltatás és foglalás törlődni fog!'
                    actions={deleteActions}
                    handleClose={() => setDeleteOpen(false)}>
        </DinoDialog>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default ExtraItems