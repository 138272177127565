import AuthBase from "../../AuthBase";
import {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useCallDataApi from "../../../hooks/data";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {
    Avatar,
    Checkbox, Divider,
    FormControl,
    FormControlLabel, FormGroup,
    InputAdornment, NativeSelect,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField, Tooltip,
    useTheme
} from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import serviceTemplate from "./ServiceTemplate";
import IconButton from "@mui/material/IconButton";
import ConfigContext from "../../../context/ConfigContext";
import Box from "@mui/material/Box";
import DinoDialog from "../../../components/Dialog";
import {getFormData} from "../../../utils/FormUtils";
import QuestionData from "./QuestionData";
import {fieldError, isUnderMaxFileSize, isValid, validateField} from "../../../utils/ValidationUtils";
import serviceSchema from "./ServiceSchema";
import Loading from "../../../components/Loading";
import businessTemplate from "../../website/BusinessTemplate";
import thousandSeparator from "../../../utils/numberUtils";
import AuthContext from "../../../context/AuthContext";
import AuthConfigContext from "../../../context/AuthConfigContext";

const ServiceDetail = () => {
    const theme = useTheme()
    const history = useHistory()
    const {baseUrl, MAX_IMG_SIZE} = useContext(ConfigContext)
    const {subscriptionPlan} = useContext(AuthContext)
    const {billingAvailable} = useContext(AuthConfigContext)

    const [categories, setCategories] = useState([])
    const [colleagues, setColleagues] = useState([])
    const [selectableColleagues, setSelectableColleagues] = useState([])
    const [prices, setPrices] = useState([])
    const [priceDeleteDialog, setPriceDeleteDialog] = useState(false)
    const [priceEditDialog, setPriceEditDialog] = useState(false)

    const [business, setBusiness] = useState(businessTemplate)
    const [questions, setQuestions] = useState([])
    const [questionDialog, setQuestionDialog] = useState(false)
    const [questionEditDialog, setQuestionEditDialog] = useState(false)
    const [questionDeleteDialog, setQuestionDeleteDialog] = useState(false)

    const [service, setService] = useState(serviceTemplate)
    const [editedService, setEditedService] = useState(serviceTemplate)
    const [serviceImage, setServiceImage] = useState('')
    const [loading, setLoading] = useState(true)

    const [current, setCurrent] = useState(-1)

    const params = useParams()
    const questionForm = useRef(null)
    const questionEditForm = useRef(null)
    const priceEditForm = useRef(null)
    const newPriceForm = useRef(null)

    const {getData: fetchService, formDataUpdateData: updateService} = useCallDataApi('service')
    const {getData: fetchCategory} = useCallDataApi('category')
    const {getData: fetchColleague} = useCallDataApi('colleague')
    const {getData: fetchBusiness} = useCallDataApi('business')
    const {
        getData: fetchPrice,
        createData: createPrice,
        deleteData: deletePrice,
        updateData: updatePrice
    } = useCallDataApi('price')
    const {
        getData: fetchQuestion,
        createData: createQuestion,
        deleteData: deleteQuestion,
        updateData: updateQuestion
    } = useCallDataApi('service-question')

    useEffect(() => {
        const getData = async () => {
            const s = await fetchService(params.id)
            const [p, q, c, b, u] = await Promise.all([
                fetchPrice(`get_for_service/?service=${s?.id}`),
                fetchQuestion(`get_for_service/?service=${s?.id}`),
                fetchCategory('get_for_business/'),
                fetchBusiness('get_business/'),
                fetchColleague('get_for_business/')
            ])
            setService(s)
            setEditedService((s))
            setPrices(p)
            setQuestions(q)
            setCategories(c)
            setBusiness(b)
            setColleagues(u)
            setLoading(false)
        }
        getData().catch(r => console.log(r))
    }, [])

    useEffect(() => {
        const usedColleagues = prices.map(p => p.colleague?.id)
        setSelectableColleagues(colleagues.filter(c => !usedColleagues.includes(c.id)))
    }, [prices])

    const isDifferentService = () => {
        return JSON.stringify(service) !== JSON.stringify(editedService)
    }

    const getColleague = (id) => {
        return colleagues.find(c => c.id === id)
    }

    const getPrice = (id) => {
        return prices.find(p => p.id === id)
    }

    const answerType = {
        '1': 'Szabad szöveges válasz',
        '2': 'Szám válasz',
        '3': 'Igen/nem',
    }

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            variant: 'contained',
            disabled: !isDifferentService() || !isValid(editedService, serviceSchema),
            endIcon: <SaveRoundedIcon/>,
            onClick: () => {
                if (!(editedService?.image instanceof File)) delete editedService['image']
                updateService(service?.id, editedService).then(s => {
                    setService(s)
                    setEditedService(s)
                })
            }
        }
    }, {
        name: 'Foglalási link másolása',
        props: {
            variant: 'outlined',
            endIcon: <ContentCopyRoundedIcon/>,
            onClick: () => {
                navigator.clipboard.writeText(`https://${business?.subdomain}.foglalapp.hu/book?c=${service.category}&s=${service.id}`)
            }
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !isDifferentService() || !isValid(editedService, serviceSchema),
            endIcon: <SaveRoundedIcon/>,
            onClick: () => {
                if (!(editedService?.image instanceof File)) delete editedService['image']
                updateService(service?.id, editedService).then(s => {
                    setService(s)
                    setEditedService(s)
                })
            }
        }
    }, {
        name: 'link',
        props: {
            variant: 'outlined',
            size: 'small',
            endIcon: <ContentCopyRoundedIcon/>,
            onClick: () => {
                navigator.clipboard.writeText(`https://${business?.subdomain}.foglalapp.hu/book?c=${service.category}&s=${service.id}`)
            }
        }
    }]

    const newQuestion = () => {
        const isValid = questionForm.current.checkValidity()
        questionForm.current.reportValidity()
        if (!isValid) return;
        let data = getFormData(questionForm.current)
        if (!('required' in data)) {
            data = {...data, required: false}
        }
        createQuestion({...data, service: params.id}).then(q => setQuestions([...questions, q]))
        setQuestionDialog(false)
    }

    const editQuestion = () => {
        const isValid = questionEditForm.current.checkValidity()
        questionEditForm.current.reportValidity()
        if (!isValid) return;
        let data = getFormData(questionEditForm.current)
        if (!('required' in data)) {
            data = {...data, required: false}
        }
        updateQuestion(`${current}/`, {
            ...data,
            service: params.id
        }).then(q => setQuestions([...questions.filter(k => k.id !== q.id), q]))
        setQuestionEditDialog(false)
    }

    const editPrice = () => {
        const isValid = priceEditForm.current.checkValidity()
        priceEditForm.current.reportValidity()
        if (!isValid) return;
        const data = getFormData(priceEditForm.current)
        updatePrice(`${current}/`, data).then(p => setPrices([...prices.filter(pr => pr.id !== p.id), p]))
        setPriceEditDialog(false)
    }

    const newPrice = () => {
        const isValid = newPriceForm.current.checkValidity()
        newPriceForm.current.reportValidity()
        if (!isValid) return;
        const data = getFormData(newPriceForm.current)
        console.log({...data, service: params.id})
        createPrice({...data, service: params.id}).then(p => setPrices([...prices, p]))
    }

    const removeQuestion = () => {
        deleteQuestion(current).then(() => setQuestions(questions.filter(q => q.id !== current)))
    }

    const removePrice = () => {
        deletePrice(current).then(() => setPrices(prices.filter(p => p.id !== current)))
    }

    const getServiceImage = () => {
        if (serviceImage instanceof File) return URL.createObjectURL(serviceImage)
        if (service?.image.startsWith('http')) return service.image
        return baseUrl + service?.image;
    }

    const validateServiceField = (field) => {
        return !validateField(field, serviceSchema, editedService[field]);
    }

    const serviceFieldError = (field) => {
        return fieldError(field, serviceSchema, editedService[field]);
    }

    return <AuthBase label={service?.name} buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.push(`/service/${service?.category}`)}>vissza</Button>
            <Masonry columns={{xs: 1, sm: 1, md: 2, xl: 2}} spacing={2} sx={{margin: 0}}>
                {/*General data*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Általános adatok</Typography>
                    <Typography variant='body2' color='#536B74'>Add meg a szolgáltatás nevét és
                        leírását!</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Szolgáltatás
                            neve*</Typography>
                        <TextField variant='standard' value={editedService?.name}
                                   helperText={validateServiceField('name') && serviceFieldError('name')}
                                   error={validateServiceField('name')}
                                   onChange={(event) => setEditedService({
                                       ...editedService,
                                       name: event.target.value
                                   })}/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Szolgáltatás rövid leírása
                            (max. xy karakter)</Typography>
                        <TextField variant='standard' multiline minRows={3} value={editedService?.description}
                                   helperText={validateServiceField('description') && serviceFieldError('description')}
                                   error={validateServiceField('description')}
                                   onChange={(event) => setEditedService({
                                       ...editedService,
                                       description: event.target.value
                                   })}/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Szolgáltatás időtartama
                            (perc)*</Typography>
                        <TextField variant='standard'
                                   helperText={validateServiceField('length') && serviceFieldError('length')}
                                   error={validateServiceField('length')}
                                   type='number' inputProps={{step: 5, min: 5}} value={editedService?.length}
                                   onChange={(event) => setEditedService({
                                       ...editedService,
                                       length: parseInt(event.target.value)
                                   })}/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>foglalások
                            elfogadása</Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={editedService?.is_accept_automatically}
                                onChange={(event) => setEditedService({
                                    ...editedService,
                                    is_accept_automatically: event.target.value === 'true'
                                })}
                            >
                                <FormControlLabel value={true} control={<Radio/>}
                                                  label={<Typography variant='caption'>Automatikus</Typography>}/>
                                <FormControlLabel value={false} control={<Radio/>}
                                                  label={<Typography variant='caption'>Manuális</Typography>}/>
                            </RadioGroup>
                        </FormControl>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Lemondás</Typography>
                        <Typography variant='body2' color='#536B74'>Amennyiben lemondhatóra van állítva, a kliensek egy
                            lemondási linket kapnak az e-mail értesítésekben.</Typography>
                        <FormControlLabel
                            control={<Switch sx={{m: 1}} checked={editedService?.cancellable}
                                             onChange={(event) => setEditedService({
                                                 ...editedService,
                                                 cancellable: event.target.checked
                                             })}/>}
                            label={<Typography variant='caption'
                                               color='#1C2427'>{editedService?.cancellable ? 'Lemondható' : 'Nem lemondható'}</Typography>}
                        />
                    </Stack>
                </div>

                {/*Visibility*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Láthatóság</Typography>
                    <Typography variant='body2' color='#536B74'>Ha nem szeretnéd, hogy a szolgáltatás látható legyen
                        a kliensek számára akkor kikapcsolhatod. (Az elrejtés előtt leadott foglalások nem fognak
                        törlődni!)</Typography>
                    <FormControlLabel
                        control={<Switch sx={{m: 1}} checked={editedService?.is_public}
                                         onChange={(event) => setEditedService({
                                             ...editedService,
                                             is_public: event.target.checked
                                         })}/>}
                        label={<Typography variant='caption' color='#1C2427'>Látható a kliensek
                            számára</Typography>}
                    />
                </div>

                {/*Picture*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatás képe</Typography>
                    <Typography variant='body2' color='#536B74'>Tölts fel egy szolgáltatásra jellemző
                        képet!</Typography>
                    <Stack direction='row' spacing={2} alignItems='center' marginTop={2}>
                        <Avatar src={getServiceImage()} sx={{
                            width: 80,
                            height: 80,
                            border: '2px solid',
                            borderColor: theme.palette.primary[500],
                            background: theme.palette.primary[50]
                        }}/>
                        <Button variant='outlined' component='label' endIcon={<FileUploadRoundedIcon/>}>Kép
                            feltöltése<input type="file" accept='image/*' hidden onChange={event => {
                                const file = event.target.files[0];
                                if (isUnderMaxFileSize(file, MAX_IMG_SIZE)) {
                                    setServiceImage(file)
                                    setEditedService({...editedService, image: file})
                                } else alert(`A kép nem lehet nagyobb mint ${MAX_IMG_SIZE} MB`)
                            }}/></Button>
                    </Stack>
                </div>

                {/*Category*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatás kategória</Typography>
                    <Typography variant='body2' color='#536B74'>Válaszd ki melyik kategóriába tartozik a
                        szolgáltatás!</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        <Typography variant='caption' color={theme.palette.primary[500]}>szolgáltatás
                            kategória*</Typography>
                        <NativeSelect
                            inputProps={{name: 'category', id: 'uncontrolled-category',}}
                            onChange={(event) => setEditedService({
                                ...editedService,
                                category: parseInt(event.target.value)
                            })}
                            value={editedService.category}
                        >
                            {categories.map((c, i) => <option key={`category_${i}`}
                                                              value={c?.id}>{c?.name}</option>)}
                        </NativeSelect>
                    </Stack>
                </div>


                {/*Category*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Számlázási beállítások</Typography>
                    <Typography variant='body2' color='#536B74'>Válaszd ki hogyan szeretnél számlázni a vevőid
                        felé!</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        <Typography variant='caption' color={theme.palette.primary[500]}>helyszíni fizetés esetén</Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-mandatory_billing_details"
                                name="controlled-mandatory_billing_details"
                                value={editedService?.mandatory_billing_details}
                                onChange={(event) => setEditedService({
                                    ...editedService,
                                    mandatory_billing_details: event.target.value
                                })}
                            >
                                <FormControlLabel value='none' control={<Radio/>} label={<Typography variant='caption'>Ne kérjen számlázási adatokat</Typography>}/>
                                <FormControlLabel value='optional' control={<Radio/>} label={<Typography variant='caption'>Opcionálisan kitöltheti</Typography>}/>
                                <FormControlLabel value='mandatory' control={<Radio/>} label={<Typography variant='caption'>Kötelezően ki kell tölteni</Typography>}/>
                            </RadioGroup>
                        </FormControl>
                        <Divider/>
                        <Typography variant='caption' color={theme.palette.primary[500]}>sikeres online fizetés esetén</Typography>
                        {business?.payment_authorized && billingAvailable() ? <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={editedService?.automatic_invoice}
                                                       onChange={(event) => setEditedService({
                                                           ...editedService,
                                                           automatic_invoice: event.target.checked
                                                       })}/>}
                                    label={<Typography variant='caption'>Automatikus számlázás</Typography>}/>
                            </FormGroup>
                            :
                            <Tooltip title="Állítsd be az online fizetést és számlázást a beállításokban ehhez az opcióhoz!">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={editedService?.automatic_invoice}
                                                           disabled
                                                           onChange={(event) => setEditedService({
                                                               ...editedService,
                                                               automatic_invoice: event.target.checked
                                                           })}/>}
                                        label={<Typography variant='caption'>Automatikus számlázás</Typography>}/>
                                </FormGroup>
                            </Tooltip>}
                    </Stack>
                </div>

                {/*Payment methods*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Fizetési lehetőségek</Typography>
                    <Typography variant='body2' color='#536B74'>Állítsd be milyen fizetési lehtőségek lehetségesek a
                        szolgáltatásnál!</Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={editedService?.is_pay_cash_or_card}
                                               onChange={(event) => setEditedService({
                                                   ...editedService,
                                                   is_pay_cash_or_card: event.target.checked
                                               })}/>}
                            label={<Typography variant='caption' color={theme.palette.primary[600]}>Helyszíni fizetés (készpénz vagy kártya)</Typography>}/>
                        {business?.payment_authorized ? <FormControlLabel
                                control={<Checkbox checked={editedService?.is_pay_online}
                                                   onChange={(event) => setEditedService({
                                                       ...editedService,
                                                       is_pay_online: event.target.checked
                                                   })}/>}
                                label={<Typography variant='caption' color={theme.palette.primary[600]}>Online
                                    fizetés</Typography>}/> :
                            <Tooltip title="Állítsd be az online fizetést a beállításokban ehhez az opcióhoz!">
                                <FormControlLabel
                                    control={<Checkbox checked={editedService?.is_pay_online}
                                                       disabled
                                                       onChange={(event) => setEditedService({
                                                           ...editedService,
                                                           is_pay_online: event.target.checked
                                                       })}/>}
                                    label={<Typography variant='caption' color={theme.palette.primary[600]}>Online
                                        fizetés</Typography>}/>
                            </Tooltip>}
                    </FormGroup>
                </div>

                {/*Max dates*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Foglalások elfogadása</Typography>
                    <Typography variant='body2' color='#536B74'>Állítsd be meddig szeretnél előre foglalásokat elfogadni a
                        szolgáltatásnál!</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Foglalások fogadása eddig</Typography>
                        <NativeSelect
                            inputProps={{name: 'accept_until', id: 'accept-until',}}
                            onChange={(event) => setEditedService({
                                ...editedService,
                                accept_until: parseInt(event.target.value)
                            })}
                            value={editedService.accept_until}
                        >
                            <option value={30}>30 nap</option>
                            <option value={60}>60 nap</option>
                            <option value={180}>180 nap</option>
                            <option value={365}>365 nap</option>
                        </NativeSelect>
                        <Typography variant='caption' color={theme.palette.primary[500]}>Foglalások fogadása ettől</Typography>
                        <NativeSelect
                            inputProps={{name: 'accept_after', id: 'accept-after',}}
                            onChange={(event) => setEditedService({
                                ...editedService,
                                accept_after: parseInt(event.target.value)
                            })}
                            value={editedService.accept_after}
                        >
                            <option value={0}>Azonnal</option>
                            <option value={1}>1 óra múlva</option>
                            <option value={2}>2 óra múlva</option>
                            <option value={3}>3 óra múlva</option>
                            <option value={4}>4 óra múlva</option>
                            <option value={5}>5 óra múlva</option>
                            <option value={24}>1 nap múlva</option>
                            <option value={48}>2 nap múlva</option>
                            <option value={72}>3 nap múlva</option>
                            <option value={-1}>Holnaptól</option>
                        </NativeSelect>
                    </Stack>
                </div>
            </Masonry>
            <Masonry columns={{xs: 1, sm: 1, md: 2, xl: 1}} spacing={2} sx={{margin: 0}}>

                {/*Colleagues*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Kollégák</Typography>
                    <Typography variant='body2' color='#536B74'>Vedd fel azokat a kollégákat akik ezt a szolgáltatást
                        nyújtják!</Typography>
                    <Stack spacing={2} marginTop={2}>
                        {prices.map((p, i) => <Stack direction='row' key={`price_${i}`} justifyContent="space-between">
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Avatar src={baseUrl + getColleague(p.colleague?.id)?.profile_picture}
                                        sx={{width: '48px', height: '48px'}}/>
                                <Stack>
                                    <Typography variant='subtitle2'>{getColleague(p.colleague?.id)?.name}</Typography>
                                    <Typography variant='caption'
                                                color='#536B74'>{getColleague(p.colleague?.id)?.title}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography variant='caption'
                                            color='#2F5C43'>{thousandSeparator(p.price)} Ft</Typography>
                                <IconButton onClick={() => {
                                    setCurrent(p.id)
                                    setPriceEditDialog(true)
                                }} sx={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary[500],
                                    background: '#fff',
                                    color: theme.palette.primary[500]
                                }}><ModeEditOutlineRoundedIcon/></IconButton>
                                <IconButton onClick={() => {
                                    setCurrent(p.id)
                                    setPriceDeleteDialog(true)
                                }} sx={{
                                    border: '2px solid',
                                    borderColor: theme.palette.primary[500],
                                    background: '#fff',
                                    color: theme.palette.primary[500]
                                }}><DeleteRoundedIcon/></IconButton>
                            </Stack>
                        </Stack>)}
                    </Stack>
                    <form ref={newPriceForm}>
                        <Stack direction={{xs: 'column', sx: 'row', md: 'row'}} marginTop={2} spacing={2}>

                            <Stack spacing={1}>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Kolléga</Typography>
                                <NativeSelect required inputProps={{name: 'colleague', id: 'uncontrolled-colleague',}}>
                                    {selectableColleagues.map((c, i) => <option key={`colleague_${i}`} value={c?.id}>{c?.name}</option>)}
                                </NativeSelect>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Ár</Typography>
                                <TextField variant='standard' required type='number' name='price' InputProps={{
                                    endAdornment: <InputAdornment position="end">Ft</InputAdornment>
                                }}/>
                            </Stack>

                            <Button variant='contained' disabled={selectableColleagues.length === 0} onClick={newPrice}
                                    sx={{alignSelf: 'end'}} endIcon={<AddCircleIcon/>}>Új kolléga hozzáadása</Button>
                        </Stack>
                    </form>
                </div>

                {/*Questions*/}
                <div className="white-card">
                    <Typography variant='subtitle1'>Szolgáltatás specifikus kérdések</Typography>
                    <Typography variant='body2' color='#536B74'>Adj hozzá a foglalási folyamathoz további kérdéseket
                        amit
                        meg szeretnél kérdezni a klienseidtől</Typography>
                    <Stack spacing={1} marginTop={2} marginBottom={2}>
                        {questions.length === 0 &&
                            <Box sx={{border: '1px solid', borderColor: '#F1F4F5', borderRadius: '16px'}}>
                                <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                                    <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek kérdések</Typography>
                                    <Typography variant='body2' color='#6F8E9A'>Adj hozzá új kérdést az alábbi gombra
                                        kattintva! 👇</Typography>
                                </Stack>
                            </Box>}
                        {questions.map((q, i) => <Stack key={`q_${i}`} spacing={2} direction='row'
                                                        justifyContent='space-between' alignItems='center'>
                            <Typography variant='body1' color='#536B74'>{q.question}{q.required && '*'}</Typography>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography variant='caption' color='#2F5C43'>{answerType[q.answer_type]}</Typography>
                                <IconButton disabled={subscriptionPlan !== 'multiple'}
                                            onClick={() => {
                                                setCurrent(q.id)
                                                setQuestionEditDialog(true)
                                            }}
                                            sx={{
                                                border: '2px solid',
                                                borderColor: theme.palette.primary[500],
                                                background: '#fff',
                                                color: theme.palette.primary[500]
                                            }}><ModeEditOutlineRoundedIcon/></IconButton>
                                <IconButton disabled={subscriptionPlan !== 'multiple'}
                                            onClick={() => {
                                                setCurrent(q.id)
                                                setQuestionDeleteDialog(true)
                                            }}
                                            sx={{
                                                border: '2px solid',
                                                borderColor: theme.palette.primary[500],
                                                background: '#fff',
                                                color: theme.palette.primary[500]
                                            }}><DeleteRoundedIcon/></IconButton>
                            </Stack>
                        </Stack>)}
                    </Stack>
                    <Button disabled={subscriptionPlan !== 'multiple'} variant='contained'
                            onClick={() => setQuestionDialog(true)} endIcon={<AddCircleIcon/>}>Új kérdés
                        hozzáadása</Button>
                </div>
            </Masonry>
        </div>

        <DinoDialog open={questionDialog}
                    title='Új kérdés hozzáadása'
                    subtitle='Add meg a kérdés adatait!'
                    handleClose={() => setQuestionDialog(false)}>
            <QuestionData reference={questionForm}/>
            <Stack direction='row' alignItems='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    newQuestion()
                }} endIcon={<AddCircleIcon/>}>Kérdés hozzáadása</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={questionEditDialog}
                    title='Kérdés szerkezstése'
                    subtitle='Add meg a kérdés adatait!'
                    handleClose={() => setQuestionEditDialog(false)}>
            <QuestionData reference={questionEditForm} obj={questions.find(q => q.id === current)}/>
            <Stack direction='row' alignItems='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    editQuestion()
                }} endIcon={<SaveRoundedIcon/>}>Változtatások mentése</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={questionDeleteDialog}
                    title='Kérdés törlése'
                    subtitle='Ha kitörlöd a kérdést a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setQuestionDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setQuestionDeleteDialog(false)
                    removeQuestion()
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceDeleteDialog}
                    title='Ár törlése'
                    subtitle='Ha kitörlöd az árat a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setPriceDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setPriceDeleteDialog(false)
                    removePrice()
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceEditDialog}
                    title='Ár szerkesztése'
                    subtitle='Add meg az új árat!'
                    handleClose={() => setPriceEditDialog(false)}>
            <form ref={priceEditForm}>
                <Stack spacing={1}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>Ár</Typography>
                    <TextField required variant='standard' name='price' defaultValue={getPrice(current)?.price}/>
                </Stack>
            </form>

            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    editPrice()
                }} endIcon={<SaveRoundedIcon/>}>Változtatások mentése</Button>
            </Stack>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>

}

export default ServiceDetail